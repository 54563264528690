import { initializeProjectIdActionAtom, projectIdAtom, projectStoreAtom } from "@/stores/Project";
import { HIDE_JOTAI_DEVTOOLS } from "@/utils/featureFlags";
import { Provider, useAtom, useAtomValue, useSetAtom } from "jotai";
import { DevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";
import { Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";
import FilterBar from "./components/FilterBar";
import DialogueModalContainer from "./components/Modals/DialogueModalContainer";
import NavBar from "./components/NavBar";
import ProjectContent from "./components/ProjectContent";
import ToastWrapper from "./components/ToastWrapper";
import { WebsocketsHandler } from "./components/WebsocketsHandler";
import style from "./index.module.css";

function ProjectPageWrapper() {
  const params = useParams<{ id: string }>();
  const projectId = useAtomValue(projectIdAtom);
  const projectStore = useAtomValue(projectStoreAtom);
  const initializeProjectIdAction = useSetAtom(initializeProjectIdActionAtom);

  useEffect(
    function initProjectIdAtom() {
      const projectId = params.id;
      if (!projectId) {
        throw new Error("Project ID in URL is required");
      }
      initializeProjectIdAction(projectId);
    },
    [initializeProjectIdAction, params.id]
  );

  if (!projectId) {
    return <></>;
  }

  return (
    <Provider store={projectStore} key={projectId}>
      {process.env.ENV === "development" && !HIDE_JOTAI_DEVTOOLS && (
        <DevTools
          store={projectStore}
          options={{ shouldShowPrivateAtoms: false, shouldExpandJsonTreeViewInitially: true }}
          position="bottom-right"
        />
      )}
      <ProjectPage projectId={projectId} />
    </Provider>
  );
}

function ProjectPage({ projectId }: { projectId: string }) {
  const [initialProjectId, setProjectId] = useAtom(projectIdAtom);

  useEffect(
    function setProjectIdOnMount() {
      setProjectId(projectId);
    },
    [projectId, setProjectId]
  );

  if (!initialProjectId) {
    return <></>;
  }

  return (
    <div className={style.wrapper}>
      {projectId && (
        <>
          <WebsocketsHandler projectId={projectId} />
          <NavBar />
          <Suspense fallback={<FilterBar.Fallback />}>
            <FilterBar />
          </Suspense>
          <ProjectContent />
          <DialogueModalContainer />
          <ToastWrapper />
        </>
      )}
    </div>
  );
}

export default ProjectPageWrapper;
